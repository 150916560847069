/******************
 * Ski Location
 ******************/
.location-facts {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  @include font-copy-s();

  &__accordion {
    svg {
      display: none;
    }
  }

  h2 {
    @include font-copy-xl();
    margin-bottom: 0;
  }

  hr {
    margin: 15px auto;
  }

  .trackLengths {
    ul {
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 5px;

        span.icon {
          position: relative;
          width: 18px;
          height: 18px;
          top: 2px;
          margin-right: 4px;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        &.blueTrack {
          span.icon svg {
            fill: $color-lightBlue;
          }
        }

        &.redTrack {
          span.icon svg {
            fill: $red;
          }
        }

        &.blackTrack {
          span.icon svg {
            fill: $black;
          }
        }
      }
    }
  }

  .lifts {
    ul {
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 5px;

        span.icon {
          position: relative;
          width: 18px;
          height: 18px;
          top: 2px;
          margin-right: 4px;

          svg {
            width: 18px;
            height: 18px;
            fill: $color-blue;
          }
        }
      }
    }
  }

  .funparks {
    ul {
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 5px;

        span.icon {
          position: relative;
          width: 18px;
          height: 18px;
          top: 2px;
          margin-right: 4px;

          svg {
            width: 18px;
            height: 18px;
            fill: $color-blue;
          }
        }
      }
    }
  }

  .prices {
    ul {
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 5px;

        .price {
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  .footNotes {
    @include font-copy-xs();
    font-weight: $font-weight-light;

    .operatedLogo {
      position: relative;
      top: -4px;

      img {
        display: inline-block;
        margin-left: 5px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    text-align: center;
    .trackLengths,
    .lifts,
    .funparks,
    .prices {
      cursor: pointer;
    }
    .lifts ul,
    .trackLengths ul,
    .funparks ul,
    .prices ul {
      margin-top: 12px;
      @include transition(all 0.6s ease-in-out);
    }
    &__accordion {
      h2 {
        display: inline;
      }
      svg {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        fill: $color-blue;
        margin-left: 5px;
        margin-top: -5px;
        @include rotate(0deg);
        @include transform-origin(50% 50%);
        @include transition(transform .6s ease-in-out);
      }
      &.show {
        svg.icon-arrow-right {
          @include rotate(90deg);
        }
      }
    }
    .footNotes {
      text-align: left;
      .pull-right {
        float: left !important;
      }
    }
    ul.visible-xs {
      visibility: hidden;
      opacity: 0;
      max-height: 0;
      margin: 0;
    }
    ul.visible-xs.show {
      visibility: visible;
      opacity: 1;
      max-height: 100%;
      margin-top: 12px;
    }
  }
}

section.skiLocationDetailContent {
  padding-top: 0;
  padding-bottom: 160px;
  @include font-copy-m();
  color: #FFFFFF;
  background-color: $color-lightBlue;

  &.red {
      background-color: $kitzSkiXpressColor;
  }

  h2 {
    font-weight: $font-weight-bold;
    margin-bottom: 30px;
    color: $color-white;
    font-size: $font-size-32px;
  }

  .moreInfoTitle {
    margin-top: 40px;
    font-weight: $font-weight-bold;
  }

  .content {
    ul {
      padding: 0;
      margin-bottom: 0;

      li {
        position: relative;
        list-style: none;
        margin-bottom: 6px;
        padding-left: 25px;

        &:before {
          position: absolute;
          top: 7px;
          left: 0;
          content: '';
          background: url('../../../../../src/brands/sbm/icomoon/SVG/bullet-white.svg');
          background-size: 11px 11px;
          width: 11px;
          height: 11px;
        }
      }
    }

    .adultConsentLink {
      @include font-copy-xs();
      font-weight: $font-weight-regular;
      margin-top: -5px;

      a {
        color: $color-white;
      }
    }
  }

  .infoContent {
    ul {
      padding: 0;
      margin-bottom: 40px;

      li {
        list-style: none;
        margin-bottom: 5px;

        .infoLabel {
          display: inline-block;
          width: 65px;
        }

        span.icon {
          position: relative;
          width: 18px;
          height: 18px;
          top: 2px;
          margin-right: 4px;

          svg {
            width: 18px;
            height: 18px;
            fill: #FFFFFF;
          }
        }

        a {
          color: #FFFFFF;
          text-decoration: none;
        }
      }
    }
  }

  .btn-booking {
    font-size: 30px;
    font-family: Exo,sans-serif;
    font-weight: 700;
    color: #fff;
    background: #2ed03c;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;
    border: 2px solid #fff;
    margin: 11px auto 0;
    transition: all .4s ease-in-out;
    max-width: 100%;
    text-overflow: ellipsis;
    margin-top: 25px;
    padding: 6px 30px;

    &:hover {
      color: #fff;
      background: #25a630;
    }
  }

  .btn-more {
    font-size: 22px;
    font-family: Exo,sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;
    padding: 6px 15px;
    margin: 0 auto;
    border: 1px solid #fff;
    color: $color-white;
    transition: all .4s ease-in-out;
    &:hover {
      border-color: #345260;
      color: #345260;
      background: #4ab8e6;
        .icon-arrow-right {
            fill: #345260;
        }
    }
  }

  .locationMapLink {
    margin-left: 35px !important;
  }
  .icon-arrow-right {
    fill: $color-white;
    height: 14px;
    width: 14px;
    transition: all .4s ease-in-out;
      &:hover {
      fill: #345260;
    }
  }
}

section.departureTimesTable {
  padding-bottom: 70px;

  .tableWrapper {
    .infoMessage {
      position: absolute;
      bottom: 30px;
      left: 15px;
      right: 15px;
      @include font-copy-xs();
      text-align: center;
      font-weight: $font-weight-light;
      line-height: 20px;
    }

    .infoMessageMobile {
      @include font-copy-xxs();
      text-align: center;
      font-weight: $font-weight-light;
      line-height: 20px;
      color: #fff;
      margin-top: 20px;
      margin-bottom: 0;
    }

    .titleRow {
      h3 {
        @include font-copy-m();
        text-align: center;
        font-weight: $font-weight-bold;
      }
    }

    .locationRow {
      border-bottom: 3px solid #FFFFFF;

      .locationNameCol {
        height: 80px;
        background: $color-gray;
        text-align: center;
        border-right: 3px solid #FFFFFF;

        p {
          @include font-copy-m();
          position: relative;
          top: 50%;
          @include translate(0, -50%);
          margin: 0;
        }
      }

      .timeCol {
        height: 80px;
        background: $color-gray-light;
        text-align: center;
        border-right: 3px solid #FFFFFF;

        p {
          @include font-copy-m();
          position: relative;
          top: 50%;
          @include translate(0, -50%);
          margin: 0;
        }
      }

      &.highlight {
        .locationNameCol,
        .timeCol {
          background: $color-lightBlue;

          p {
            color: #FFFFFF;
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .tableWrapper {
      .locationRow {
        border: 0;

        .locationNameCol {
          height: 60px;
          border-right: 0;
        }

        .departureAccordion {
          cursor: pointer;
          padding-top: 18px;
          padding-bottom: 18px;
          margin-bottom: 5px;
          p {
            display: inline-block;
          }
          svg {
            display: inline-block;
            width: 10px;
            height: 10px;
            fill: $color-blue;
            margin-left: 5px;
            @include rotate(0deg);
            @include transform-origin(50% 50%);
            @include transition(transform .6s ease-in-out);
          }
          &.show {
            background: $color-lightBlue;
            margin-bottom: 0;
            p {
              color: $color-white;
              font-weight: 600;
            }
            svg {
              fill: $color-white;
            }
            svg.icon-arrow-right {
              @include rotate(90deg);
            }
          }
        }

        .locationWrapper {
          background: $color-lightBlue;
          opacity: 0;
          visibility: hidden;
          max-height: 0;
          &.show {
            opacity: 1;
            visibility: visible;
            max-height: 100%;
            margin-bottom: 5px;
            padding-top: 0;
            padding-bottom: 10px;
            .timeCol {
              margin-bottom: 15px;
            }
          }
        }

        .titleRow {
          h3 {
            margin: 0;
            color: $color-blue;
          }
        }

        .timeCol {
          height: 45px;
          background: transparent;
          border-right: 0;

          p {
            color: $color-white;
            font-weight: 600;
          }
        }
      }
    }
  }
}

section.weatherForecastTable {
  padding-top: 70px;
  padding-bottom: 70px;

  .tableWrapper {
    overflow-x: initial;
    @media screen and (max-width: 1061px) {
      width: 100%;
      margin-bottom: 16.5px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd;
    }

      h2 {
          font-weight: 500;
          margin-bottom: 15px;
      }

    table {
      thead {
        tr {
          th {
            border-bottom: none;
            vertical-align: middle;

            h3 {
              @include font-copy-m();
              text-align: center;
              font-weight: $font-weight-bold;
              margin-bottom: 0;
            }

            &.highlight {
              h3 {
                color: $color-lightBlue;
              }
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 3px solid #FFFFFF;

          td {
            vertical-align: middle;

            &.timeCol {
              background: $color-gray;

              p {
                display: block;
                min-width: 48px;
                @include font-copy-s();
                text-align: center;
                font-weight: $font-weight-bold;
                margin-bottom: 0;
              }
            }

            background: $color-gray-light;
            border-right: 3px solid #FFFFFF;

            &:last-child {
              border-right: none;
            }

            .colRow {
              .col {
                float: left;

                &.iconCol {
                  width: 24%;
                  padding-right: 10px;

                  .icon {
                    width: 30px;
                    height: 30px;
                    display: block;
                    margin: 5px auto;

                    svg {
                      width: 30px;
                      height: 30px;
                    }
                  }
                }

                &.tempCol {
                  width: 60%;
                  text-align: left;
                  padding-left: 5px;

                  .tempLabel {
                    display: inline-block;
                    width: 30px;
                    font-weight: $font-weight-bold;
                    font-size: 13px;
                    position: relative;
                    top: -1px;
                  }

                  .tempMountain,
                  .tempValley {
                    display: block;
                    margin: 0 auto;
                    width: 105px;
                    font-size: 15px;
                  }

                  &.en {
                    .tempMountain,
                    .tempValley {
                      width: 121px;
                    }

                    .tempLabel {
                      width: 57px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

section.extra-content-snippet {
    padding-bottom: 100px;

    .disturber-img {
        margin-bottom: 30px;
        width: 250px;

        @include media-breakpoint-down(sm){
            margin-top: 30px;
        }
    }

    .contact-info-text, .contact-info-box {
        font-size: 15px;
    }

    .contact-info-box {
        display: inline-block;
        margin-bottom: 15px;
        padding: 10px 18px;
        color: white;
        background-color: $kitzSkiXpressColor;

        &:not(:last-of-type) {
            margin-right: 10px;
        }

        @media (max-width: $screen-md-max) and (min-width: $screen-sm-min), (max-width: 379px) {
            display: block;

            &:not(:last-of-type) {
                margin-right: 0;
            }
        }
    }
}
@include media-breakpoint-down(sm){
    section.headerStageSlider {
        height: 666px;
        .headerStageSliderWrapper {
            height: 666px;
            .headerStageSlide {
                overflow: hidden;
                .headerImage {
                    float: right;
                    height: auto;
                    width: 1420px;
                    right: -300px;
                }
                .overlayEdgeArea {
                    position: absolute;
                    bottom: 0;
                }
            }
        }
        .snowBrushWrapper {
            .snowBrush {
                width: 400px;
                height: auto;
            }
        }
    }

    h2 {
        text-align: center;
        font-weight: 600;
        margin-bottom: 25px;
    }
    section.megaMenu {
        .navigationWrapper {
            bottom: 0;
        }
        .bottomOverlay {
            display: none;
        }
    }
    section.headerStageSlider {
        .headerStageSliderWrapper {
            .headerStageSlide {
                .headerContent {
                    top: 50px;

                    .bookingButtonWrapper {
                        .btn-booking {
                            font-size: 24px;
                            height: auto;
                            line-height: 46px;

                            .priceTagWrapper {
                                margin-left: auto;
                                margin-right: auto;
                                left: 0;
                                right: 0;
                                top: -40px;
                            }
                            .price {
                                font-size: 30px !important;
                            }
                        }
                    }
                    h1 {
                        font-size: 37px;
                        line-height: 40px;
                        padding: 0 5px;
                    }

                    .headline-container {
                        margin-bottom: 40px;
                        h1 {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
    section.teaserBoxWrapper {
        article.teaserItem {
            .itemLink {
                padding: 15px 25px;
            }
            h1.title {
                margin-top: 0;
                line-height: 30px;
            }
            .overlayContent {
                h2 {
                    margin-bottom: 15px;
                    font-size: 15px;
                }
                .ctaButton {
                    font-size: 13px;
                }
            }
            .operatedLogo {
                width: 110px;
                height: auto;
            }
        }
    }
    section.imageTeaserArea {
        padding-top: 80px;
        ul {
            padding: 0;
            li {
                span.icon {
                    margin-right: 8px;
                }
            }
        }
        .btn-more {
            margin-top: 60px;
            height: fit-content;
            line-height: 33px;
            span.icon {
                top: 0;
            }
            .priceTagWrapper {
                margin-left: auto;
                margin-right: auto;
                left: 0;
            }
        }
    }
    section.newsletterArea {
        .newsletterFormWrapper {
            form.newsletter {
                .emailWrapper {
                    float: none;
                    width: 100%;
                    input[name="email"] {
                        text-align: center;
                    }
                }
                button {
                    width: 50%;
                    float: none;
                    display: block;
                    margin: 25px auto 0;
                }
            }
        }
    }
    section.mapArea {
        #skiLocationsGoogleMap {
            height: 450px;
        }
    }
    section.headerStage {
        height: 650px;
        margin-top: 0px;
        .headerStageWrapper {
            height: 650px;

            .headerContent {
                top: 100px;

                .btn-booking {
                    margin-top: 45px;
                    padding: 8px 40px;
                    min-width: 250px;
                    font-size: 17px;
                    height: auto;

                    .priceTagWrapper {
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                        top: -40px;
                        width: 180px;
                        height: 50px;

                        span.icon-pricetag {
                            width: 180px;
                            height: 50px;

                            svg {
                                top: -65px;
                                width: 200px;
                                height: 180px;
                            }
                        }

                        .price {
                            font-size: 30px !important;
                            line-height: 45px;

                            .decimal-places {
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
    section.skiLocationDetailContent {
        .btn-booking {
            display: block;
            width: 100%;
            font-size: 18px;
        }
    }
    section.departureTimesTable {
        .tableWrapper {
            .locationRow {
                border: 0;
                .locationNameCol {
                    height: 60px;
                }
                .departureAccordion {
                    cursor: pointer;
                    padding-top: 18px;
                    padding-bottom: 18px;
                    margin-bottom: 5px;
                    p {
                        display: inline-block;
                    }
                    svg {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        fill: $color-blue;
                        margin-left: 5px;
                        @include rotate(0deg);
                        @include transform-origin(50% 50%);
                        @include transition(transform .6s ease-in-out);
                    }
                    &.show {
                        background: $color-lightBlue;
                        margin-bottom: 0;
                        p {
                            color: $color-white;
                            font-weight: 600;
                        }
                        svg {
                            fill: $color-white;
                        }
                        svg.icon-arrow-right {
                            @include rotate(90deg);
                        }
                    }
                }
                .locationWrapper {
                    background: $color-lightBlue;
                    opacity: 0;
                    visibility: hidden;
                    max-height: 0;
                    &.show {
                        opacity: 1;
                        visibility: visible;
                        max-height: 100%;
                        margin-bottom: 5px;
                        padding-top: 0;
                        padding-bottom: 10px;
                        .timeCol {
                            margin-bottom: 15px;
                        }
                    }
                }
                .titleRow {
                    h3 {
                        margin: 0;
                        color: $color-blue;
                    }
                }
                .timeCol {
                    height: 45px;
                    background: transparent;
                    p {
                        color: $color-white;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    section.skiLocationTeaserColumnsWrapper {
        .teaserItemWrapper {
            .teaserItemCol {
                .mainArea {
                    .titleWrapper {
                        display: flex;
                        justify-content: center;
                        h1 {
                            max-width: 70%;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 450px) {
    section.headerStageSlider {
        .snowBrushWrapper {
            .snowBrush {
                display: none;
            }
        }
    }
}
@media (max-width: 350px) {
    section.headerStageSlider {
        .snowBrushWrapper {
            .snowBrush {
                display: none;
            }
        }
    }
    section.headerStageSlider {
        .headerStageSliderWrapper {
            .headerStageSlide {
                .headerContent {
                    .btn-booking {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    section.headerStage {
        .headerStageWrapper {
            .headerContent {
                .btn-booking {
                    font-size: 14px;
                }
            }
        }
    }
    section.imageTeaserArea {
        h1 {
            text-align: left;
        }
    }
}
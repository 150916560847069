body.fullscreen {
    position: relative;
    .box.active {
        position: fixed;
        top: 70px;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        background: white;
        z-index: 100;
        margin-top: 0;
        max-height: fit-content;
    }
    .departure-list {
        overflow: auto;
    }
    .weather-info-box-wrapper, .snow-info-box-wrapper, .departure-info-box-wrapper {
        position: unset;
    }
}

header {
    position: fixed;
    font-size: 18px;
    top: 0;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 100;
    background-color: $white;
    padding: 24px 48px;
    box-shadow: 0 3px 6px $grey-2;

    @include media-breakpoint-down(md) {
        padding: 24px;
    }

    @include media-breakpoint-down(xl) {
        padding: 18px;
        font-size: 16px;
    }

    @include media-breakpoint-down(sm) {
        padding: 12px;
    }

    @include hamburger-static;
    @include hamburger-sticky;

    &.sticky, &.always-sticky {
        background-color: black;
        padding: $default-space 0;
        @include transition(all 500ms);
        @include box-shadow(0 5px 10px 0 rgba($grey-2, 0.5));

        @include media-breakpoint-down(sm) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    // hamburger color
    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        width: 40px;
        height: 4px;
        border-radius: 4px;
        position: absolute;
        background-color: $body-color;
        @include transition(background-color 500ms);
    }

    .header-wrapper {
        margin: 4px;

        div {
            margin: auto 0;
        }

        .icon-wrapper {
            display: flex;
            flex-direction: row;
            align-content: flex-end;

            &__icon {
                margin-left: 64px;
                width: 40px;
                height: 40px;

                img {
                    width: 40px;
                    height: 40px;

                    @include media-breakpoint-down(sm) {
                        width: 28px;
                        height: 28px;
                    }
                }

                @include media-breakpoint-down(xl) {
                    margin-left: 32px;
                }

                @include media-breakpoint-down(md) {
                    margin: 0 16px;
                    align-content: center;
                }
                @include media-breakpoint-down(sm) {
                    margin: 0 5px;

                    width: 30px;
                    height: 30px;
                }
            }

            &__icon:hover {
                filter: invert(67%) sepia(11%) saturate(4874%) hue-rotate(170deg) brightness(104%) contrast(80%);
            }

            @include media-breakpoint-down(lg) {
                align-content: center;
            }
        }

        .logo {
            padding: 0;
        }

        nav.main-navigation {
            margin: auto 10px;

            ul.main-nav-list {
                display: flex;
                list-style: none;
                padding-left: 0;
                text-align: center;
                justify-content: space-around;
                margin: auto 0;

                li.main-nav-item {
                    display: inline-block;
                    text-transform: uppercase;
                    font-weight: 400;
                    position: relative;

                    a {
                        color: black;
                        text-decoration: none;

                        &:hover {
                            text-shadow: 0 0 1px black;
                        }
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    &:first-child {
                        padding-left: 0;
                    }

                    @include media-breakpoint-down(xxl) {
                        margin: auto 1.5em;
                    }

                    @include media-breakpoint-down(xl) {
                        margin: auto 1em;
                    }

                    @include media-breakpoint-between(sm, md) {
                        font-size: 0.93em;
                        padding: $default-space 12px;
                    }

                    ul {
                        display: none;
                    }
                }

                li.main-nav-item::after {
                    content: "";
                    display: block;
                    width: 100%;
                    opacity: 0;
                    height: 2px;
                    background: $color-lightBlue;
                    transition: width 0.3s;
                    margin: 0 auto;
                }

                li.main-nav-item:hover {

                    ul {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        left: -10px;
                        background-color: white;
                        padding: 40px 12px 12px;
                        gap: 12px;
                        font-size: 14px;
                        text-align: left;
                        list-style: none;
                    }
                }

                li.main-nav-item:hover::after {
                    position: absolute;
                    opacity: 1;
                }

            }
        }

        .language-switch {
            background-color: $white;
            color: black;
            align-content: flex-end;

            ul {
                list-style: none;
                margin: 0;
                display: inline-block;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
                padding-left: 0;

                li {
                    display: inline-block;
                    padding: 0 5px;
                    width: 20px;
                    line-height: 1;

                    + li {
                        border-left: 1px solid black;
                    }

                    a, span {
                        text-decoration-line: none;
                        color: black;
                        @include transition(all 500ms);
                    }

                    a {
                        font-weight: 300;

                        &:hover, &:focus {
                            font-weight: 500;
                        }
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    &:first-child {
                        padding-left: 0;
                        margin-right: 5px;
                    }

                    @include media-breakpoint-between(md, lg) {
                        font-size: 0.93em;
                    }
                }
            }
        }

        #mobile-navigation {
            display: none;
        }
    }



    // departure, weather & snow data info boxes

    .weather-info-box-wrapper, .snow-info-box-wrapper, .departure-info-box-wrapper {
        position: relative;
    }

    .icon-wrapper__icon:not(:hover) {
        img.active {
            filter: invert(67%) sepia(11%) saturate(4874%) hue-rotate(170deg) brightness(104%) contrast(80%);
        }
    }

    .weather-info-box, .snow-info-box, .departure-info-box {
        display: none;
        &.active {
            display: block;
        }
    }

    &.weather-info-box, &.snow-info-box, &.departure-info-box .box {
        .weather-location-name-title, .snow-location-name-title, .departure-location-name-title {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .weather-location-filter-link, .snow-location-filter-link, .departure-location-filter-link {
            font-weight: $font-weight-regular;
        }
    }

    &.snow-info-box .box {
        min-height: 170px;
    }

    .box {
        display: none;
        position: absolute;
        top: 31px;
        width: 290px;
        min-height: fit-content;
        background: $white;
        padding: 10px 15px;
        text-align: left;

        &.active {
            display: block;
        }

        &.left {
            left: -50px;
            top: 70px;
        }

        &.right {
            right: -50px;
            top: 70px;
            min-height: 300px;
            display: flex;
            flex-direction: column;
        }

        h2 {
            @include font-copy-l();
            text-align: left;
            margin-top: 0;
        }

        .snow-location-name-title, .weather-location-name-title, .departure-location-name-title {
            @include font-copy-xs();
            font-weight: $font-weight-bold;
            margin-bottom: 0;
            margin-top: -15px;
        }

        .location-filter-link,
        .weather-location-filter-link,
        .snow-location-filter-link,
        .departure-location-filter-link {
            @include font-copy-xs();
            font-weight: $font-weight-bold;
            text-decoration: none;

            span.icon {
                position: relative;
                width: 16px;
                height: 16px;
                top: 3px;
                display: inline-block;
                @include translate-rotate(0, 0, 0deg);
                @include transform-origin(center center);
                @include transition(transform .4s ease-in-out);

                svg {
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    width: 10px;
                    height: 10px;
                    fill: $color-gray-dark;
                }
            }

            width: fit-content;
            border: none;
            background: none;
            padding: 0;

            &.open {
                span.icon {
                    @include translate-rotate(0, 0, 90deg);
                }
            }
        }

        ul.ski-areas,
        ul.weather-locations,
        ul.snow-locations,
        div.departure-locations {
            padding: 0;
            display: none;
            margin-bottom: 10px;
            list-style: none;

            div, li {
                text-align: left;

                .location-filter,
                .weather-location-filter,
                .snow-location-filter,
                .departure-location-filter {
                    @include font-copy-xs();
                    color: $color-blue;
                    font-weight: $font-weight-regular;
                    text-transform: none;
                    text-align: left;
                    text-decoration: none;

                    span.icon {
                        position: relative;
                        width: 15px;
                        height: 15px;
                        margin-left: 4px;
                        display: inline-flex;

                        svg {
                            position: relative;
                            top: 2px;
                            width: 15px;
                            height: 15px;
                            * {
                                fill: $color-blue;
                            }
                        }
                    }

                    &.active {
                        font-weight: $font-weight-bold;
                    }
                }
            }
            &.open {
                display: block;
                opacity: 1;
            }

            .card.card-body {
                padding: 0;
                border: none;
            }
        }

        ul.temperature-list {
            list-style: none;
            padding: 0;

            .temperature-item {
                display: none;

                &.active {
                    display: block;
                }

                ul.temperature-time-list {
                    padding: 0;
                    margin-top: 10px;
                    list-style: none;

                    li {
                        border-bottom: 1px solid $color-gray;
                        padding: 4px 0;

                        &:last-child {
                            border-bottom: none;
                        }

                        .item-cols {
                            height: 50px;

                            .col {
                                float: left;
                                height: 50px;

                                &.time-col {
                                    width: 25%;
                                    text-align: center;

                                    .time {
                                        line-height: 50px;
                                        font-size: 14px;
                                    }
                                }

                                &.icon-col {
                                    width: 25%;

                                    .icon {
                                        width: 40px;
                                        height: 40px;
                                        display: block;
                                        margin: 5px auto;

                                        svg {
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }
                                }

                                &.temp-col {
                                    width: 50%;
                                    text-align: left;

                                    .temp-label {
                                        display: inline-block;
                                        width: 30px;
                                        font-weight: $font-weight-bold;
                                        font-size: 13px;
                                        position: relative;
                                        top: -1px;
                                    }

                                    .temp-mountain,
                                    .temp-valley {
                                        display: block;
                                        margin: 0 auto;
                                        width: 82px;
                                    }

                                    &.en {
                                        .temp-mountain,
                                        .temp-valley {
                                            width: 110px;
                                        }

                                        .temp-label {
                                            width: 57px;
                                        }
                                    }
                                }
                            }
                        }

                        .btn-booking {
                            display: block;
                            font-size: 17px;
                        }
                    }
                }
            }
        }

        ul.snow-list {
            list-style: none;
            padding: 0;

            .snow-item {
                display: none;

                &.active {
                    display: block;
                }

                .item-cols {
                    height: 50px;

                    .col {
                        float: left;
                        height: 50px;

                        &.time-col {
                            width: 35%;
                            text-align: center;

                            .snow-type {
                                line-height: 50px;
                                font-size: 14px;
                            }
                        }

                        &.icon-col {
                            width: 15%;

                            .icon {
                                display: block;
                                margin: 10px auto 0 auto;
                            }

                            .icon,
                            .icon svg {
                                width: 30px;
                                height: 30px;
                                fill: $color-lightBlue;
                            }
                        }

                        &.temp-col {
                            width: 50%;
                            text-align: left;

                            .temp-label {
                                display: inline-block;
                                width: 30px;
                                font-weight: $font-weight-bold;
                                font-size: 13px;
                                position: relative;
                                top: -1px;
                            }

                            .temp-mountain,
                            .temp-valley {
                                display: block;
                                margin: 0 auto;
                                width: 105px;
                            }

                            &.en {
                                .temp-mountain,
                                .temp-valley {
                                    width: 121px;
                                }

                                .temp-label {
                                    width: 57px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .departure-list {
            padding: 0;

            li {
                position: relative;
                padding: 0;
                margin: 0;
                display: none;
                width: 100%;
                list-style: none;

                &.active {
                    display: block;

                }

                &.out-of-scope {
                    display: none;
                }

                .departure-item {
                    padding: 5px 0 5px 40px;
                    border-bottom: 1px solid $color-gray;
                    position: relative;

                    .skibus-tooltip {
                        &__content {
                            display: none;
                        }
                        &.open {
                            .skibus-tooltip__content {
                                z-index: 80;
                                position: absolute;
                                display: block;
                                right: 0;
                                padding: 10px;
                                background: white;
                                box-shadow: 0 0 10px 0 rgba($color-gray-dark, 0.5);
                                border-radius: 2px;
                                p {
                                    margin: 0;
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .status {
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 1px;
                        width: 30px;

                        &.green {
                            background: $color-green;
                        }

                        &.yellow {
                            background: $color-yellow;
                        }

                        &.red {
                            background: $color-red;
                        }

                        span.icon {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            right: 0;
                            @include translate(0, -50%);
                            width: 20px;
                            height: 20px;
                            display: block;
                            margin: 0 auto;

                            svg {
                                width: 20px;
                                height: 20px;
                                fill: $white;
                            }
                        }
                    }

                    .low-remaining-spaces {
                        font-size: 14px;
                    }

                    .content {
                        text-align: left;
                        padding-top: 5px;

                        .title-col {
                            width: 70%;
                            float: left;
                            position: relative;

                            span.icon-canceled {
                                position: absolute;
                                z-index: 1;
                                left: 11px;
                                right: 0;
                                top: 0;
                                bottom: 38%;
                                overflow: hidden;

                                svg {
                                    fill: $color-red;
                                    position: relative;
                                    width: 100%;
                                    height: auto;
                                    top: -61px;
                                    padding-right: 10px;
                                }
                            }
                        }

                        .operated-col {
                            width: 30%;
                            float: left;

                            span.icon-info {
                                position: relative;
                                display: block;
                                width: 15px;
                                height: 15px;
                                margin: 5px auto;
                                cursor: pointer;

                                svg {
                                    width: 15px;
                                    height: 15px;
                                    fill: $color-red;
                                }
                            }

                            span.icon-sold-out {
                                position: relative;
                                display: block;
                                width: 72px;
                                height: 35px;
                                overflow: hidden;
                                margin-top: 2px;
                                margin-bottom: -15px;

                                svg {
                                    width: 72px;
                                    height: 72px;
                                    position: relative;
                                    top: -18px;
                                }
                            }

                            .btn-booking {
                                font-size: 15px;
                                padding: 4px 5px;
                                margin-top: 5px;
                                margin-bottom: -20px;
                                min-width: 70px;
                            }
                        }

                        .date-wrapper {
                            margin-bottom: 0;

                            .day {
                                @include font-copy-xxs();
                                font-weight: $font-weight-bold;
                            }

                            .date {
                                @include font-copy-l();
                                font-weight: $font-weight-light;
                            }
                        }

                        .departure-location-name {
                            @include font-copy-xs();
                            margin-bottom: 0;
                            line-height: 18px;
                        }

                        .operated-label {
                            @include font-copy-xxs();
                            color: $color-gray-dark;
                            text-align: center;
                            margin-bottom: 2px;
                        }
                    }

                    .departures-row {
                        display: block;
                        width: 100%;

                        .departure-times-link {
                            @include font-copy-xxs();
                            color: $color-gray-dark;
                            text-decoration: none;

                            span.icon {
                                position: relative;
                                width: 16px;
                                height: 16px;
                                top: 4px;
                                display: inline-block;
                                @include translate-rotate(0, 0, 0deg);
                                @include transform-origin(center center);
                                @include transition(transform .4s ease-in-out);

                                svg {
                                    position: absolute;
                                    top: 3px;
                                    left: 3px;
                                    width: 10px;
                                    height: 10px;
                                    fill: $color-gray-dark;
                                }
                            }

                            &.open {
                                span.icon {
                                    @include translate-rotate(0, 0, 90deg);

                                    svg {
                                    }
                                }
                            }
                        }

                        .departure-times {
                            position: relative;
                            display: none;
                            margin-bottom: 5px;

                            &.open {
                                display: block;
                            }

                            p {
                                @include font-copy-xxs();
                                color: $color-blue;
                                margin-bottom: 0;

                                span.time {
                                    display: inline-block;
                                    width: 50px;
                                    font-weight: $font-weight-bold;
                                    margin-right: 5px;
                                }
                            }

                            span.icon-info {
                                position: absolute;
                                right: 25px;
                                top: 50%;
                                display: block;
                                width: 15px;
                                height: 15px;
                                cursor: pointer;
                                @include translate(0, -50%);

                                svg {
                                    width: 15px;
                                    height: 15px;
                                    fill: $color-blue;
                                }
                            }
                        }
                    }
                }

                &.status-red {
                    .departure-item .content {
                        .date-wrapper .day,
                        .date-wrapper .date,
                        .location-name {
                            color: $color-red;
                        }
                    }
                }
            }
        }

        .source-claim {
            margin-top: auto;
            margin-bottom: 0;
            font-size: 13px;
            color: $color-gray-dark;

            a {
                color: $color-gray-dark;
                text-decoration: none;
            }
        }

    }

    &.info-box.active,
    &.show-on-load {
        > a {
            background: $white;
            color: $color-blue;

            .icon svg {
                fill: $color-blue;
            }
        }

        .box {
            display: block;
        }
    }
}

#mobile-navigation {
    .mm-navbars-bottom {
        .socialShareBar__item-text {
            display: none;
        }
    }

    .mm-navbar {
        .language-switch {
            width: 100%;
            display: inline-block;

            ul {
                display: inline-block;
                list-style: none;
                padding: 0;
                margin-bottom: 0;

                li {
                    float: left;
                    padding: 0 5px;
                    border-right: 1px solid black;
                    color: black;

                    &:last-child {
                        border-right: 0;
                    }

                    a, span {
                        font-size: 20px;
                    }

                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
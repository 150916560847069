.b-wysiwyg {
    h1, h2, h3, h4, h5, h6 {
        color: $color-blue;
        margin-bottom: 10px;
    }

    h1, .h1 {
        font-size: $font-size-h1;
    }
    
    h2, .h2 {
        font-size: $font-size-h2;
    }

    h3, .h3 {
        font-size: 28px;
        font-weight: $font-weight-bold;
        text-transform: none;
    }
    h4, .h4 {
        font-size: 20px;
        font-weight: $font-weight-bold;
    }
    h5, .h5 {
        font-size: 16px;
        font-weight: $font-weight-bold;
    }
    h6, .h6 {
        font-size: 14px;
        font-weight: $font-weight-bold;
    }
    p {
        margin-bottom: 0;
        padding-bottom: 1rem;
    }
}
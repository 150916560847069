$name: '.footer';

footer {
    font-family: $font-family-base;
    position: relative;
    z-index: 6;
    max-width: 1920px;
    margin: 0 auto;
    background-color: $white;
    box-shadow: 0 30px 20px 10px rgba(0, 0, 0, 0.15);

    #{$name}__main {
        #{$name}__column-headline-link, #{$name}__column-headline {
            color: #345260;
            font-weight: 700;
            font-size: 14px;
            text-decoration: none;
        }
        #{$name}__column-headline-link {
            &:hover {
                text-decoration: underline;
            }
        }
        #{$name}__column-link {
            font-size: 14px;
            line-height: 30px;
            font-weight: 300;
            color: #345260;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        #{$name}__column-four {
            p {
                font-weight: 600;
                line-height: 30px;
                margin: 0;
                > a {
                    text-decoration: none;
                    font-size: 14px;
                }
            }
        }
    }

    #{$name}__bottom {
        background-color: #4ab8e6;
        font-size: 14px;
        line-height: 30px;
        font-weight: 600;
        &-copyright {
            color: #fff;
        }
        &-legal-links, &-legal-link {
            color: #fff;
            text-decoration: none;
        }
        &-cta {
            a {
                color: #fff;
                font-size: 22px;
                font-weight: 700;
                text-decoration: none;
            }
            background-color: #2ed03c;
            text-align: center;
            text-transform: uppercase;
            padding: 6px 15px;
            transition: all .4s ease-in-out;
            max-width: 100%;
            text-overflow: ellipsis;
            &:hover {
                background-color: #25a630;
            }
        }
    }
}
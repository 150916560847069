/* === Text Mixins === */

@mixin text-uppercase {
    text-transform: uppercase;
}
@mixin font-copy-xl {
    font-size: 25px;
    line-height: 25px;
    color: $color-blue;
    font-family: $font-family-base;
    font-weight: $font-weight-light;
}
@mixin font-copy-l {
    font-size: 22px;
    line-height: 30px;
    color: $color-blue;
    font-family: $font-family-base;
    font-weight: $font-weight-light;
}
@mixin font-copy-m {
    font-size: 18px;
    line-height: 25px;
    color: $color-blue;
    font-family: $font-family-base;
    font-weight: $font-weight-light;
    @media (max-width: $screen-sm-max) {
        font-size: 16px;
        line-height: 23px;
    }
}
@mixin font-copy-s {
    font-size: 16px;
    line-height: 23px;
    color: $color-blue;
    font-family: $font-family-base;
    font-weight: $font-weight-light;
}
@mixin font-copy-xs {
    font-size: 14px;
    line-height: 30px;
    color: $color-blue;
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
}
@mixin font-copy-xxs {
    font-size: 10px;
    line-height: 15px;
    color: $color-blue;
    font-family: $font-family-base;
    font-weight: $font-weight-light;
}
// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal $padding-vertical;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}
// quadratic images
@mixin quadratic-image($max-size: 100%) {
    padding-top: 100%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        max-width: $max-size;
        max-height: $max-size;
    }
}
// transforms
@mixin transform($transform) {
    -webkit-transform: $transform;
    -ms-transform: $transform; // IE9 only
    -o-transform: $transform;
    transform: $transform;
}
@mixin translate-rotate($x, $y, $degrees) {
    -webkit-transform: translate($x, $y) rotate($degrees);
    -ms-transform: translate($x, $y) rotate($degrees); // IE9 only
    -o-transform: translate($x, $y) rotate($degrees);
    transform: translate($x, $y) rotate($degrees);
}
@mixin transform-origin($transform-origin) {
    -webkit-transform-origin: $transform-origin;
    -ms-transform-origin: $transform-origin; // IE9 only
    -o-transform-origin: $transform-origin;
    transform-origin: $transform-origin;
}
// browser support flexbox
@mixin flex-parent($flex-flow: row wrap, $align-items: stretch, $justify-content: space-between) {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */

    -webkit-flex-flow: $flex-flow;
    -moz-flex-flow: $flex-flow;
    -ms-flex-flow: $flex-flow;
    flex-flow: $flex-flow;
    -webkit-align-items: $align-items;
    -moz-align-items: $align-items;
    -ms-align-items: $align-items;
    align-items: $align-items;
    -webkit-justify-content: $justify-content;
    -moz-justify-content: $justify-content;
    -ms-justify-content: $justify-content;
    justify-content: $justify-content;
}
@mixin flex-child() {
}
// font sizes flow mixin
@mixin flow-font-size($flow-variable, $flow-index: auto) {
    $flow-length: length($flow-variable);

    @if $flow-index == "auto" {
        @include flow-font-size($flow-variable, 1);
        @media (min-width: $screen-sm-min) { @include flow-font-size($flow-variable, 2); }
        @media (min-width: $screen-md-min) { @include flow-font-size($flow-variable, 3); }
        @media (min-width: $screen-lg-min) { @include flow-font-size($flow-variable, 4); }
    } @else if $flow-index >= $flow-length {
        font-size: nth($flow-variable, $flow-length);
    } @else {
        font-size: nth($flow-variable, $flow-index);
    }
}
// Icon
@mixin icon-fill($fill: #FFFFFF) {
    fill: $fill;
}
@mixin icon($size, $fill: null) {
    @if $size == tiny {
        width: 10px;
        height: 10px;
    } @else if $size == small {
        width: 16px;
        height: 16px;
    } @else {
        width: $size;
        height: $size;
    }
    @if $fill {
        @include icon-fill($fill);
    }
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin retina($img, $scale, $size) {
    background-image: url('#{$img}');
    background-size: $size;

    @media
    only screen and (-webkit-min-device-pixel-ratio: $scale),
    only screen and (min--moz-device-pixel-ratio: $scale),
    only screen and (-o-min-device-pixel-ratio: $scale/1),
    only screen and (min-resolution: #{$scale * 96}dpi) {
        background-image: url('#{$img}');
    }
}

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees); // IE9 only
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
    // Firefox
    &::-moz-placeholder {
        color: $color;
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
    &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
    &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Responsive image
//
// Keep images from scaling beyond the width of their parents.
@mixin img-responsive($display: block) {
    display: $display;
    max-width: 100%; // Part 1: Set a maximum relative to the parent
    height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}
.b-newsletter {
    background: $alpenPlusSkiExpressColor;

    .iframe-content {
        padding: 30px 0 70px 0;
    }

    .logo {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .form-text {
        color: $white;
        margin-bottom: 15px;
        font-size: 18px;
    }

    .info-text {
        display: block;
        color: $white;
        margin-top: 15px;
    }

    #pc-cookie-notice {
        display: none;
    }

    .editable-content {
        text-align:left;
    }

    .cr-font {
        font-size: 14px;
    }

    .wrapper, .cr-page {
        margin: 0 auto 10px auto;
        text-align: left;
        border-radius: 4px;
    }

    .cr-body label {
        margin-bottom: 5px;
        color: $white;
        float: none;
        clear: both;
        display: block;
        width: auto;
        margin-top: 8px;
        text-align: left;
        font-weight: bold;
        position: relative;
    }

    .formbox {
        line-height: 150%;
        //font-family: Helvetica;
        font-size: 12px;
        color: #333333;
        padding: 20px;
        background-color: rgba(255, 255, 255, .2);
        border-radius: 6px 6px 6px 6px;
    }

    .submit-container {
        text-align: center;
        margin-top: 30px;
    }
}
/**
 * Bootstrap Overrides
 */
$white: #fff;
$black: #1c1c1b;
$blue: #1d70b7;
$blue-dark: #1C3A69;
$blue-light: #007bff;
$green: #acd333;
$grey-0: #f5f5f5;
$grey-1: #e2e1e1;
$grey: $grey-1;
$grey-2: #868686;
$grey-3: #575756;
$yellow: #ffb300;
$red: #e63055;
$body-color: $grey-3;

/*
  only put custom variables here,
  change values of standard bootstrap variables in bootstrap's variables.less
*/

$imagepath: "/static/src/sbm/img";
$fontpath: "/static/src/sbm/dist/fonts";
$icon-font-path: "/static/src/sbm/dist/vendor/bootstrap-sass-3.3.7/assets/fonts/bootstrap/";

/* === Colors === */
$color-green: #2ed03c;
$color-lightBlue: #4AB8E6;
$color-blue: #345260;
$color-gray-dark: #5D5D5D;
$color-gray: #D9D9D9;
$color-gray-light: #F5F5F5;
$color-white: #fff;
$color-black: #13262D;
$color-red: #FF7276;
$color-yellow: #ffe000;

$color-white-grey: #F1F1F1;
$color-grey-light: #E5E5E5;
$color-grey: #DADADA;
$color-grey-dark: #494949;
$color-grey-darker: #3C3C3B;
$color-grey-blue: #B2C3D5;
$color-orange: #F9B21F;
$color-ice-blue: #E0E6EE;
$color-mint: #17BDA5;
$text-color: $color-grey-darker;

$kitzSkiXpressColor: #CC151A;
$alpenPlusSkiExpressColor: #0683b7;

//same primary color as msr since sbm didnt have any (for eg. accordion brick)
$color-primary: $color-blue;

/* === Fonts === */
$font-family-base: 'Exo', sans-serif;
$font-family-base-light: 'Exo', sans-serif;
$font-family-base-bold: 'Exo', sans-serif;
$font-family-condensed: 'Exo', sans-serif;
$font-family-condensed-bold: 'Exo', sans-serif;
$fontfamily-fontawesome-brands: 'Font Awesome 5 Brands';

// font weight is beeing controlled via font-family
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

$font-size-base: 1rem; // 16px
$font-size-teaser-subheadline: 14px;
$font-size-menu: $font-size-base;
$font-size-menu-top: 14px;
$font-size-tab: 20px;
$font-size-footer-base: 14px;
$font-size-footer-headline: 18px;
$font-size-headline: 40px;
$font-size-headline-medium: 22px;
$font-size-headline-sub: 24px;
$font-size-headline-teaser: 17px;

/*
$line-height-base: strip-unit(22px / $font-size-base);
$line-height-footer-base: strip-unit(22px / $font-size-footer-base);
$line-height-menu: strip-unit(22px / $font-size-menu);
$line-height-menu-top: strip-unit(22px / $font-size-menu-top);
$line-height-headline-medium: strip-unit(30px / $font-size-headline-medium);
$line-height-headline-sub: strip-unit(30px / $font-size-headline-sub);
$line-height-headline-teaser: strip-unit(25px / $font-size-headline-teaser);
$line-height-tab: strip-unit(22px / $font-size-tab);
*/

$font-size-h1: 40px;
$font-size-h1-sm: $font-size-h1;
$font-size-h1-xs: $font-size-h1;

$font-size-h2: 32px;
$font-size-h2-sm: $font-size-h2;
$font-size-h2-xs: $font-size-h2;

$headline-text-shadow: 0 4px 3px rgba(0, 0, 0, .3);

/* === Bricks === */
$brick-padding: 75px;
$brick-in-brick-padding: 20px;

$brick-header-bottom-space: 30px;
$brick-footer-top-space: 30px;

/* === Borders === */


/* === Buttons === */


/* === Panels === */

/* === Spacer === */
$spacer: 35px;
$default-space: 15px;

/* === Sizes === */
$header-height: 140px;
$header-height-mobile: 60px;
$logo-width: 268px;
$nav-left-offset: $logo-width + 2 * $spacer;
$max-width: 1600px;
$top-navigation-height: 50px;
$flag-width: 35px;
$flag-height: 22px;
$search-field-margin: 5px;
$sticky-link-padding: 10px;

/* === Home Teasers === */
$home-teaser-elements: 4;
$home-teaser-height: 500px;
$home-teaser-zoom-percent: 150%;
$home-teaser-zoom: 1.5;

$flow-font-size-h1: $font-size-h1 $font-size-h1 $font-size-h1-sm $font-size-h1-xs;
$flow-font-size-h2: $font-size-h2 $font-size-h2 $font-size-h2-sm $font-size-h2-xs;
$flow-font-size-menu: $font-size-menu $font-size-menu $font-size-menu $font-size-menu;
$flow-font-size-text: $font-size-base $font-size-base $font-size-base $font-size-base;

$hexagon-big: 460px;

/* === font sizes === */
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-24px: 1.5rem;
$font-size-32px: 2rem;
$font-size-36px: 2.25rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;


$screen-xs-max: 575px;
$screen-sm-min: $screen-xs-max + 1;
$screen-sm-max: 767px;
$screen-md-min: $screen-sm-max + 1;
$screen-md-max: 991px;
$screen-lg-min: $screen-md-max + 1;
$screen-lg-max: 1199px;

$border-width:3px;

/* === Interferer === */

$interferer-icon: url("../../../brands/sbm/img/icon-skibus-muenchen.svg");
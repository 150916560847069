
@include media-breakpoint-down(md){
    section.skiLocationTeaserColumnsWrapper {
        .teaserItemWrapper {
            .teaserItemCol {
                float: none;
                margin: 0 auto;
            }
        }
    }

    section.skiDayTripTeaserWrapper {
        .teaserItemRow {
            .mainArea {
                width: 100%;
                max-width: 310px;
                float: none;
                height: auto;
                padding: 0;
                margin: 0 auto;
                .inner {
                    height: auto;
                    .teaserImageWrapper {
                        margin-right: -10px;
                        .snowOverlay.right {
                            display: none;
                        }
                    }
                    .titleWrapper,
                    .date,
                    .description {
                        padding-left: 15px;
                    }
                    .priceWrapper {
                        margin: 0 auto;
                    }
                }
            }
            .datesArea {
                width: 100%;
                max-width: 310px;
                float: none;
                height: auto;
                margin: 0 auto;
                padding-bottom: 10px;
            }
        }
    }

    section.headerStage {
        .headerStageWrapper {
            .headerContent {
                top: 100px;

                .btn-booking {
                    .priceTagWrapper {
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}
html {
    height: 100%;
}

body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    header > .centerLogo {
        display: block;
    }

    &.editmode {
        header {
            height: 200px;
        }
    }
}

#siteWrapper {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    box-shadow: 0 2px 20px 10px rgba(0,0,0,.15);

    .container .container {
        padding-left: 0;
        padding-right: 0;
    }

    > .centerLogo {
        display: block;
        position: absolute;
        top: 120px;
        z-index: 10;
        left: 50%;
        @include translate(-50%, 0);
    }
}

hr {
    border-top: 1px solid #eee;
    opacity: 1;
}

/******************
 * SEASON END PAGE OVERLAY
 ******************/
#seasonEndPageOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 1000;
    overflow-y: auto;

    &.hide {
        display: none;
    }

    .innerContent {
        position: relative;
        top: 40px;
        left: 50%;
        width: 100%;
        max-width: 800px;
        padding: 20px;
        @include translate(-50%, 0);

        .logo {
            display: block;
            margin: 0 auto;
        }

        .responsiveVideoWrapper {
            position: relative;
            padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
            padding-top: 0;
            height: 0;
            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .title {
            @include font-h1();
            color: $white;
            text-shadow: $headline-text-shadow;
            text-align: center;
            margin-top: 52px;
        }

        .subTitle {
            position: relative;
            @include font-h2();
            font-weight: $font-weight-light;
            color: $white;
            text-shadow: $headline-text-shadow;
            text-align: center;
            margin-top: 20px;
        }

        .btn-booking {
            position: relative;
            @include font-h3();
            color: $white;
            margin-top: 45px;
            height: 65px;
            line-height: 44px;
            padding: 10px 90px;
            @media (max-width: 675px) {
                padding: 10px 40px;
            }
            font-weight: $font-weight-bold;
            max-width: 90%;
        }

        .leftIcon {
            span.icon-ski-cross {
                position: absolute;
                left: -80px;
                top: 40%;

                svg {
                    width: 65px;
                }
            }
        }

        .rightIcon {
            span.icon-ski-cross {
                position: absolute;
                right: -80px;
                top: 40%;

                svg {
                    width: 65px;
                }
            }
        }
    }

    .disturber {
        position: absolute;
        right: 10%;
        top: 400px;

        img {
            width: 300px;
        }

        @media (max-width: 1450px) {
            right: 0;
        }
        @media (max-width: 1200px) {
            right: 50%;
            @include translate(50%, 0);
            top: 700px;
        }

        @media (max-width: 1024px) {
            right: 120px;
            top: 500px;
            img {
                width: 200px;
            }
        }

        @media (max-width: 980px) {
            img {
                width: 150px;
            }
        }

        @media (max-width: 895px) {
            right: 50%;
            @include translate(50%, 0);
            top: 700px;
            img {
                width: 200px;
            }
        }

        @media (max-width: 450px) {
            right: 50%;
            @include translate(50%, 0);
            top: 500px;
            img {
                width: 180px;
            }
        }
    }
}

section.headerStageSlider {
    position: relative;
    width: 100%;
    margin-top: 100px;
    height: 875px;
    z-index: 6;
    background: $color-lightBlue;
    overflow: hidden;

    .swiper-pagination.swiper-pagination-bullets {
        bottom: 200px;
        .swiper-pagination-bullet {
            background: none;
            border: 2px solid $grey-0;
            &.swiper-pagination-bullet-active {
                background: $grey-1;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        margin-top: 95px;
    }
    @include media-breakpoint-down(lg) {
        margin-top: 85px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 75px;
    }

    .slick-slider .slick-list, .slick-slider .slick-track {
        @include transform(none);
    }

    .snowBrushWrapper {
        position: relative;
        width: 1920px;
        left: 0;
        z-index: 5;

        .snowBrush {
            position: absolute;
            left: 0;
            top: -150px;
        }
    }

    .headerStageSliderWrapper {
        position: relative;

        .headerStageSlide {
            position: relative;
            width: 100%;

            .headerImageWrapper {

            }

            .headerImage {
                position: relative;
                width: 100%;
                max-width: 1920px;
                object-fit: cover;
            }

            .imageOverlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: -moz-linear-gradient(top, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.2) 40%, rgba(19, 38, 45, 0) 50%);
                background: -webkit-linear-gradient(top, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.2) 40%, rgba(19, 38, 45, 0) 50%);
                background: linear-gradient(to bottom, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.2) 40%, rgba(19, 38, 45, 0) 50%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#13262d', endColorstr='#0013262d', GradientType=0);
                opacity: 0.4;
            }

            .headerContent {
                position: absolute;
                z-index: 6;
                top: 220px;
                left: 0;
                right: 0;
                text-align: center;

                .headline-container {
                    position: relative;
                    display: inline-block;
                    @include media-breakpoint-down(sm) {
                        position: unset;
                    }

                    .stoerer-wrapper {
                        position: absolute;
                        top: 40px;
                        left: 50px;
                        @include translate(-100%, 0);
                        width: 300px;
                        height: 300px;
                        z-index: 100;

                        @media (max-width: 1368px) {
                            width: 200px;
                            height: 200px;
                            top: 100px;
                            left: 40px;
                        }

                        @include media-breakpoint-down(lg) {
                            top: -170px;
                            left: 120px;
                            @include translate(0, 0);
                        }

                        @include media-breakpoint-down(sm) {
                            top: 97%;
                            left: 50%;
                            @include translate(-50%, 0);
                        }

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                h1 {
                    @include font-h1();
                    color: $white;
                    text-shadow: $headline-text-shadow;
                }

                h2 {
                    @include font-h2();
                    font-weight: $font-weight-light;
                    color: $white;
                    text-shadow: $headline-text-shadow;
                }

                .bookingButtonWrapper {
                    display: block;

                    .btn-booking {
                        position: relative;
                        @include font-h2();
                        color: $white;
                        height: 60px;
                        line-height: 46px;
                        margin-top: 45px;

                        .priceTagWrapper {
                            position: absolute;
                            left: -35px;
                            top: -42px;
                            width: 170px;
                            height: 50px;

                            span.icon-pricetag {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 170px;
                                height: 50px;
                                overflow: hidden;

                                img {
                                    position: relative;
                                    top: -62px;
                                    filter: invert(67%) sepia(11%) saturate(4874%) hue-rotate(170deg) brightness(104%) contrast(80%);
                                    width: 170px;
                                }
                            }

                            .price {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                @include font-h3();
                                font-size: 50px;
                                text-transform: none;
                                text-align: center;
                                color: $white;
                                font-weight: $font-weight-bold;
                                margin-top: 6px;

                                span.decimal-places {
                                    font-size: 1.5rem;
                                }
                            }
                        }

                        .passportInfo {
                            position: absolute;
                            bottom: -50px;
                            right: -170px;
                        }
                    }
                }
            }
        }

        .slick-dots {
            position: relative;
            width: 100%;
            top: -100px;
            z-index: 6;
            list-style: none;
            text-align: center;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                display: inline-block;
                height: 10px;
                width: 10px;
                margin: 0 2px;
                padding: 0;
                cursor: pointer;

                button {
                    display: block;
                    border: 2px solid lighten($color-gray-dark, 30%);
                    width: 10px;
                    height: 10px;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    border-radius: 10px;
                    background: none;
                    outline: 0;
                    line-height: 0;
                    font-size: 0;
                    color: transparent;
                    padding: 0;
                    cursor: pointer;
                }

                &.slick-active {
                    button {
                        background: lighten($color-gray-dark, 30%);
                    }
                }
            }
        }
    }
}

section.headerStage {
    margin-top: 100px;
    position: relative;
    width: 100%;
    height: 520px;
    z-index: 8;
    background: $color-lightBlue;
    overflow: hidden;

    .snowBrushWrapper {
        position: relative;
        width: 1920px;
        left: 0;
        z-index: 5;

        .snowBrush {
            position: absolute;
            left: 0;
            top: -150px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .headerStageWrapper {
        position: relative;
        width: 100%;
        height: 520px;

        .headerImage {
            position: relative;
            left: 50%;
            @include translate(-50%, 0);

            @include media-breakpoint-down(sm) {
                top: $header-height-mobile;
            }
        }

        .imageOverlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: -moz-linear-gradient(top, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.2) 40%, rgba(19, 38, 45, 0) 50%);
            background: -webkit-linear-gradient(top, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.2) 40%, rgba(19, 38, 45, 0) 50%);
            background: linear-gradient(to bottom, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.2) 40%, rgba(19, 38, 45, 0) 50%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#13262d', endColorstr='#0013262d', GradientType=0);
            opacity: 0.4;
        }

        .headerContent {
            position: absolute;
            z-index: 8;
            top: 125px;
            left: 0;
            right: 0;
            text-align: center;

            @include media-breakpoint-down(sm) {
                top: 180px;
            }

            &.default {
                top: 200px;
            }

            .headline-container {
                position: relative;
                display: inline-block;

                @include media-breakpoint-down(sm) {
                    max-width: 90%;
                }

                .stoerer-wrapper {
                    position: absolute;
                    top: -20px;
                    left: -100px;
                    @include translate(-100%, 0);
                    width: 250px;
                    height: 250px;
                    z-index: 100;

                    @include media-breakpoint-down(md) {
                        top: -50px;
                        left: 0;
                    }

                    @include media-breakpoint-between(sm,md) {
                        @include translate(-80%, 0);
                        width: 150px;
                        height: 150px;
                        left: 6px;
                        top: -20px;
                    }

                    @include media-breakpoint-down(sm) {
                        top: 0;
                        @include translate(-15%, -90%);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            h1 {
                @include font-h1();
                color: $white;
                text-shadow: $headline-text-shadow;
            }

            h2 {
                @include font-h2();
                font-weight: $font-weight-light;
                color: $white;
                text-shadow: $headline-text-shadow;
            }

            .btn-booking-col {
                text-align: center;
                margin-bottom: 40px;

                @include media-breakpoint-up(sm) {
                    &.left {
                        text-align: right;
                    }
                    &.right {
                        text-align: left;
                    }
                }
            }

            .btn-booking {
                position: relative;
                padding: 15px 40px;
                @include font-h2();
                color: $color-white;
                font-family: $font-family-base;
                background: #2ed03c;
                border-radius: 0;
                height: 70px;
                line-height: 40px;
                margin-top: 60px;
                margin-right: 30px;

                .priceTagWrapper {
                    position: absolute;
                    left: -30px;
                    top: -60px;
                    width: 250px;
                    height: 70px;

                    span.icon-pricetag {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 250px;
                        height: 70px;
                        overflow: hidden;

                        svg {
                            position: relative;
                            top: -95px;
                            fill: $color-lightBlue;
                            width: 250px;
                            height: 250px;
                        }
                    }

                    .price {
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        right: 0;
                        @include font-h3();
                        font-size: 50px !important;
                        text-transform: none;
                        text-align: center;
                        color: $white;
                        font-weight: $font-weight-bold;
                        line-height: 70px;

                        .decimal-places {
                            font-size: $font-size-h2
                        }
                    }

                    &.red {
                        span.icon-pricetag {
                            svg {
                                fill: $kitzSkiXpressColor;
                            }
                        }
                    }
                }

                .passportInfo {
                    position: absolute;
                    bottom: -36px;
                    right: -50px;
                    z-index: 2;
                    width: 250px;
                    height: auto;

                    @include media-breakpoint-between(sm,md) {
                        right: -10px;
                    }
                }
            }
        }
    }
}

section.stage-area.editmode {
    margin-bottom: 50px;
    padding: 50px 0;
    background-color: #F6F6F6;
    .container {
        width: 50%;
        margin: auto;
    }
}

/******************
 * Overlay Edge Area
 ******************/

section.overlayEdgeArea {
    position: relative;
    width: 100%;
    height: 666px;
    margin-top: -520px;
    margin-bottom: -146px;
    overflow: hidden;
    z-index: 7;
    pointer-events: none;

    &.small {
        height: 560px;
        margin-top: -350px;
    }

    &.blue, &.red {
        height: 204px;
        margin-top: -160px;
        margin-bottom: -2px;
    }

    &.split {
        height: 204px;
        margin-top: -160px;
        margin-bottom: -2px;
    }

    &.ski {
        position: absolute;
        bottom: 0;
        height: 438px;
        margin: 0;
    }

    &.map {
        height: 122px;
        margin-top: -28px;
        margin-bottom: -2px;
    }

    .bottomEdge {
        position: absolute;
        top: 0;
        left: 50%;
        @include translate(-50%, 0);
        width: 1920px;
    }
}

/******************
 * Overview Map
 ******************/

section.overviewMap {
    margin-bottom: 28px;

    img {
        width: 100%;
    }
}

/******************
 * Ski Location Teaser Columns Area
 ******************/

section.skiLocationTeaserColumnsArea {
    position: relative;
    padding-top: 130px;
    padding-bottom: 220px;
    background: $color-lightBlue;
    overflow: hidden;

    &.editmode {
        padding-bottom: 100px;
    }

    &.night {
        background: $color-blue;
    }

    > .container {
        position: relative;
        z-index: 8;
    }

    .stoerer-wrapper {
        position: absolute;
        top: 200px;
        right: -20px;
        @include translate(0, -80%);
        z-index: 10;

        @include media-breakpoint-up(lg) {
            right: 30px;
        }

        @include media-breakpoint-down(lg) {
            width: 200px;
            height: 200px;
            img {
                width: 200px;
                height: 200px;
            }
        }

        img {
            @include translate-rotate(0,0, 15deg);
        }
    }

    h2 {
        font-size: $font-size-40px;
        line-height: $font-size-45px;
        font-family: $font-family-base;
        font-weight: 700;
        color: $color-white;
        text-align: center;
        @include media-breakpoint-down(sm) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    h3 {
        font-size: $font-size-30px;
        font-family: $font-family-base;
        font-weight: 300;
        color: $color-white;
        text-align: center;
        text-transform: none;
        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
    }

    .snowBrushWrapper {
        position: relative;
        width: 1920px;
        left: 50%;
        @include translate(-50%, 0);

        .snowBrushLeft {
            position: absolute;
            left: 0;
            top: 0;
        }

        .snowBrushRight {
            position: absolute;
            right: 0;
            top: 270px;
            width: 30%;
            height: auto;
        }
    }

}

/******************
 * Ski Location Teaser Rows Area
 ******************/

section.skiLocationTeaserRowsArea {
    position: relative;
    padding-top: 60px;
    padding-bottom: 100px;
    background: $white;
    overflow: hidden;
    z-index: 6;

    h2 {
        @include font-h2();
        color: $color-lightBlue;
        text-align: center;
    }

    h3 {
        @include font-h3();
        color: $color-lightBlue;
        text-align: center;
    }
}

/******************
 * Detail Page Departure Tables Snippet
 ******************/

section.detailHeaderSection {
    &.editmode {
        padding-top: 130px;
        padding-bottom: 220px;
        background: $color-lightBlue;
    }
    position: relative;
    overflow: hidden;

    .headerStage.sbm-background-color {
        background: $color-lightBlue;
    }

    > .container {
        position: relative;
        z-index: 6;
    }
    .stoerer-wrapper {
        position: absolute;
        top: 200px;
        right: -20px;
        @include translate(0, -80%);
        z-index: 10;

        @include media-breakpoint-up(lg) {
            right: 30px;
        }

        @include media-breakpoint-down(lg) {
            width: 200px;
            height: 200px;
            img {
                width: 200px;
                height: 200px;
            }
        }

        img {
            @include translate-rotate(0,0, 15deg);
        }
    }

    h2 {
        font-size: $font-size-40px;
        line-height: $font-size-45px;
        font-family: $font-family-base;
        font-weight: $font-weight-bold;
        color: $color-white;
        text-align: center;
    }

    h3 {
        font-size: $font-size-40px;
        font-family: $font-family-base;
        font-weight: $font-weight-light;
        color: $color-white;
        text-align: center;
    }

    .snowBrushWrapper {
        position: relative;
        width: 1920px;
        left: 50%;
        @include translate(-50%, 0);

        .snowBrushLeft {
            position: absolute;
            left: 0;
            top: 0;
        }

        .snowBrushRight {
            position: absolute;
            right: 0;
            top: 270px;
            width: 30%;
            height: auto;
        }
    }

    .container {
        .form-group {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

section.detailDepartureTableSnippetSection {
    margin: 40px 0;
    .container > label {
        margin-bottom: 20px;
    }
}

/******************
 * Ski Location Teaser Columns
 ******************/

section.skiLocationTeaserColumnsWrapper {
    display: inline-block;
    position: relative;
    left: 50%;
    @include translate(-50%, 0);
    margin: 35px 0;
    max-width: 100%;
    text-align: center;

    .teaserItemWrapper {
        position: relative;
        display: inline-block;

        @include media-breakpoint-up(sm) {
            width: calc((100% / 2) - 4px);
        }

        @include media-breakpoint-up(xl) {
            width: calc((100% / 4) - 4px);
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .teaserItemCol {
            margin: 0 auto;
            width: 100%;

            @include media-breakpoint-up(xl) {
                position: relative;
                float: left;
                margin: 0 2px;
            }

            .separator {
                border-bottom: 1px solid lighten($color-gray-dark, 50%);
                margin: 8px;
            }

            .mainArea {
                width: 100%;
                background: $white;
                margin-bottom: 4px;
                text-align: left;

                .titleWrapper {
                    position: relative;
                    top: -20px;
                    width: 100%;
                    height: 50px;
                    padding: 0 4px;

                    h1 {
                        position: relative;
                        top: 50%;
                        @include translate(0, -50%);
                        @include font-h4();
                        text-align: center;
                        color: $color-lightBlue;
                        margin: 0;

                        a {
                            color: $color-lightBlue;
                            text-decoration: none;

                            &:hover, &:active, &:focus {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .teaserImageWrapper {
                    position: relative;

                    .snowOverlay {
                        margin-top: -30%;
                        width: 100%;
                    }
                    a.skiBusTooltip {
                        position: absolute;
                        width: 15px;
                        height: 15px;
                        top: 10px;
                        left: 10px;
                        z-index: 2;

                        svg.icon-copyright {
                            position: relative;
                            width: 15px;
                            height: 15px;
                            fill: $color-lightBlue;
                        }
                    }
                }

                .dateWrapper {
                    position: relative;
                    width: 100%;
                    height: 40px;
                    top: -7px;
                    margin-bottom: -5px;

                    .date {
                        position: relative;
                        top: 50%;
                        @include translate(0, -50%);
                        @include font-copy-xs();
                        color: $color-blue;
                        text-align: center;
                        line-height: 20px;
                        margin: 0;
                    }
                }

                ul.factList {
                    padding-left: 12px;
                    margin: 15px 0;

                    li {
                        @include font-copy-s();
                        list-style: none;
                        margin-bottom: 7px;

                        @include media-breakpoint-between(md,lg) {
                            font-size: 14px;
                        }

                        .icon {
                            position: relative;
                            width: 15px;
                            height: 15px;
                            margin-right: 4px;

                            svg {
                                width: 15px;
                                height: 15px;
                                fill: $color-blue;
                            }
                        }
                    }
                }

                .priceCategory {
                    @include font-copy-xs();
                    text-align: center;
                    font-weight: $font-weight-light;
                    margin-bottom: -16px;
                }

                .priceWrapper {
                    position: relative;
                    overflow: hidden;

                    .icon-pricetag {
                        position: absolute;
                        top: -23px;
                        left: 50%;
                        @include translate(-50%, 0);
                        fill: $color-lightBlue;
                        width: 160px;
                        height: 160px;
                    }

                    .price {
                        position: relative;
                        font-size: 28px;
                        text-align: center;
                        font-weight: $font-weight-bolder;
                        color: $white;
                        margin: 15px 0;

                        .decimal-places {
                            font-size: 1.5rem;
                        }
                    }
                }

                .priceInfo {
                    @include font-copy-xs();
                    text-align: center;
                    margin-bottom: 5px;
                    font-weight: $font-weight-bold;
                }

                .btn-booking {
                    font-size: $font-size-22px;
                    font-family: $font-family-base;
                    font-weight: $font-weight-bold;
                    color: $white;
                    background: #2ed03c;
                    text-align: center;
                    text-transform: uppercase;
                    border-radius: 0;
                    padding: 6px 15px;
                    border: 2px solid $white;
                    margin: 11px auto 0;
                    transition: all .4s ease-in-out;
                    max-width: 100%;
                    text-overflow: ellipsis;

                    position: relative;
                    left: 50%;
                    @include translate(-50%, 0);
                    height: 45px;
                    line-height: 29px;

                    @include media-breakpoint-between(md,lg) {
                        font-size: 19px;
                    }

                    &:hover {
                        color: $white;
                        background: #25a630;
                    }
                }

                .moreLink {
                    @include font-copy-xs();
                    display: block;
                    font-weight: $font-weight-light;
                    text-align: center;
                    margin-top: 4px;
                    text-decoration: none;

                    span.icon-arrow-right {
                        position: relative;
                        top: -2px;
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        fill: $color-blue;

                        svg {
                            width: 8px;
                            height: 8px;
                            fill: $color-blue;
                        }
                    }
                }
            }

            .datesArea {
                width: 100%;
                height: 197px;
                background: $white;

                .datesTitle {
                    @include font-copy-xs();
                    text-align: center;
                    margin-top: 0;
                    padding-top: 10px;
                    margin-bottom: 3px;
                }

                ul.dates {
                    padding: 0;
                    text-align: center;

                    li {
                        list-style: none;
                        @include font-copy-s();
                        margin-bottom: 0;

                        .status {
                            position: relative;
                            top: -1px;
                            display: inline-block;
                            width: 9px;
                            height: 9px;
                            -webkit-border-radius: 9px;
                            -moz-border-radius: 9px;
                            border-radius: 9px;
                            margin-right: 5px;

                            &.green {
                                background: $color-green;
                            }

                            &.yellow {
                                background: $color-yellow;
                            }

                            &.red {
                                background: $color-red;
                            }
                        }

                        .date {
                            display: inline-block;
                            width: 130px;
                            text-align: left;
                        }
                    }
                }

                .operatedNote {
                    @include font-copy-xxs();
                    text-align: center;
                    font-weight: $font-weight-light;
                    color: $color-gray-dark;
                    margin: 0;
                }

                .operatedLogo {
                    img {
                        margin: 0 auto;
                    }
                }
            }


            &__big {
                width: 372px;

                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                }

                .mainArea {
                    height: auto !important;
                }

                .snowOverlay {
                    width: 100%;
                    margin-top: -80px !important;
                }
            }
        }

        /* dark theme */
        &--dark-theme {
            .teaserItemCol {
                .teaserImageWrapper {
                    &:before {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                        background-color: rgba($color-blue, 0.8);
                        position: absolute;
                        pointer-events: none;
                    }

                    .snowOverlay {
                        z-index: 1;
                        position: relative;
                    }
                }

                .titleWrapper {
                    z-index: 2;
                }

                .datesArea {
                    background-color: $color-blue;
                    color: $color-white;

                    .datesTitle, .operatedNote {
                        color: $color-white;
                    }
                }

                .dates {
                    .date {
                        color: $color-white;
                    }
                }

                .mainArea {
                    position: relative;

                    .priceInfo {
                        color: $color-white;
                    }

                    .teaserItemWrapper__bottom {
                        position: relative;
                        z-index: 1;
                    }

                    .snowOverlay__darkTheme {
                        position: absolute;
                        bottom: 0;
                        z-index: 0;
                    }
                }
            }
        }
    }
}

section.skiLocationTeaserMobileNavigationWrapper {
    .mobileTeaserNavigationItem {
        position: relative;
        width: 180px !important;
        height: 95px;
        background: $white;
        margin: 15px 20px;

        .titleWrapper {
            position: relative;
            width: 100%;
            height: 50px;
            padding: 0 4px;

            h1 {
                position: relative;
                top: 50%;
                @include translate(0, -50%);
                @include font-copy-s();
                font-weight: $font-weight-bold;
                text-align: center;
                color: $color-lightBlue;
                margin: 0;
            }
        }

        .dateWrapper {
            position: relative;
            width: 100%;
            height: 40px;
            margin-bottom: -5px;

            .date {
                position: relative;
                top: 50%;
                @include translate(0, -50%);
                @include font-copy-xs();
                color: $color-blue;
                text-align: center;
                line-height: 20px;
                margin: 0;
            }
        }

        span.icon-dreieck {
            position: absolute;
            width: 25px;
            height: 25px;
            left: 50%;
            @include translate(-50%, 0);
            bottom: -18px;

            svg {
                width: 25px;
                height: 25px;
                fill: $white;
            }
        }
    }

    .slick-active {
        span.icon-dreieck {
            display: block;
        }
    }
}

.skiDayTripTeaserWrapper .teaser-item-col-wrapper:nth-child(n+3) {
    margin-top: 30px;

}
section.skiDayTripTeaserWrapper .teaser-item-col {
    .teaser-item-col-header {
        min-height: 310px;
    }
    .teaser-item-col-body {
        min-height: 330px;
    }
}

.teaser-alignment {
    display: flex;
    flex-wrap: wrap;

    &-- {
        &left {
            justify-content: flex-start;
        }

        &center {
            justify-content: center;
        }

        &right {
            justify-content: flex-end;
        }
    }
}

/******************
 * Ski Location Teaser Rows
 ******************/

section.skiDayTripTeaserWrapper {
    $teaserGutter: 20px;
    margin-top: 40px;

    &.cols {
        @include make-row($teaserGutter);
    }

    .teaserItemRow, .teaser-item-col {
        h1 {
            @include font-h4();
            color: $color-lightBlue;
        }

        .priceWrapper {
            position: relative;
            overflow: hidden;
            width: 140px;

            .icon-pricetag {
                position: absolute;
                top: -23px;
                fill: $color-lightBlue;
                width: 140px;
            }

            .price {
                position: relative;
                @include font-h3();
                font-size: 27px;
                text-align: center;
                font-weight: $font-weight-bold;
                color: $white;
                margin: 12px 0;

                .decimal-places {
                    font-size: 1.5rem;
                }
            }
        }

        .operatedNote {
            @include font-copy-xs();
            font-weight: $font-weight-light;
            color: $color-gray-dark;
            display: inline-block;
            position: relative;
            margin: 0 10px 0 10px;
        }

        .operatedLogo {
            display: inline-block;
            position: relative;

            img {
                margin: 0 auto;
            }
        }

        .season-dates {
            position: relative;
            @include font-copy-s();
            color: $color-blue;
            margin: 0;

            span.icon-calender {
                position: relative;
                top: 1px;
                width: 15px;
                height: 15px;
                margin-right: 10px;

                svg {
                    top: 0;
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .description {
            @include font-copy-s();
            padding-left: 30px;
        }

        .datesTitle {
            @include font-copy-xs();
            margin-top: 0;
            margin-bottom: 3px;
        }

        ul.dates {
            padding: 0 3px;

            li {
                list-style: none;
                @include font-copy-s();
                margin-bottom: 0;

                .status {
                    position: relative;
                    top: -1px;
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    -webkit-border-radius: 9px;
                    -moz-border-radius: 9px;
                    border-radius: 9px;
                    margin-right: 5px;

                    &.green {
                        background: $color-green;
                    }

                    &.yellow {
                        background: $color-yellow;
                    }

                    &.red {
                        background: $color-red;
                    }
                }

                .date {
                    display: inline-block;
                    width: 115px;
                    margin-right: 3px;
                }
            }
        }

        .btn-more {
            border-color: $color-blue;
            color: $color-blue;

            &:hover {
                background: $color-blue;
                color: $color-white;
                .icon-arrow-right {
                    fill: $color-white;
                }
            }

            .icon-arrow-right {
                width: 10px;
                height: 10px;
                fill: $color-blue;
            }
        }
    }

    .teaserItemRow {
        width: 100%;
        margin-bottom: 4px;

        .separator {
            border-bottom: 1px solid lighten($color-gray-dark, 50%);
            margin: 8px 0;
        }

        .mainArea {
            width: 70%;
            height: 230px;
            float: left;
            padding-right: 4px;

            .disturberPlate {
                position: absolute;
                top: -30px;
                right: 10px;
            }

            .inner {
                border: 1px solid $color-lightBlue;
                height: 230px;
                padding-right: 10px;
                background: $white;
            }

            .titleWrapper {
                position: relative;
                width: 100%;
                height: 50px;
                padding: 0 4px;

                h1 {
                    position: relative;
                    top: 50%;
                    @include translate(0, -50%);
                    margin: 0;
                }
            }

            .teaserImageWrapper {
                position: relative;

                .snowOverlay {
                    position: absolute;
                    background-size: cover;

                    &.bottom {
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 80px;
                        @include retina("../img/snow_teaser.png", 2, "100% 100%");
                    }

                    &.right {
                        top: 0;
                        bottom: 0;
                        right: -5px;
                        width: 80px;
                        @include retina("../img/snow_teaser_rotated.png", 2, "100% 100%");
                    }
                }
            }

            .date {
                position: relative;
                @include font-copy-s();
                color: $color-blue;
                margin: 0;

                span.icon-calender {
                    position: relative;
                    top: 1px;
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;

                    svg {
                        top: 0;
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            .description {
                @include font-copy-s();
                padding-left: 30px;
            }

            .operatedNote {
                top: -30px;
            }

            .operatedLogo {
                top: -15px;
            }
        }

        .datesArea {
            width: 30%;
            height: 230px;
            float: left;
            border: 1px solid $color-lightBlue;
            padding: 0 15px;
            background: $white;

            .datesTitle {
                padding-top: 10px;
            }
        }
    }

    .teaser-item-col-wrapper {
        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
    }

    .teaser-item-col {
        position: relative;
        @include media-breakpoint-down(sm) {
            margin-top: 35px;
        }

        .teaser-item-col-disturber {
            position: absolute;
            top: -20px;
            left: 20px;
            z-index: 5;
        }

        .teaser-item-col-header, .teaser-item-col-body {
            background-color: white;
        }

        .teaser-item-col-header {
            position: relative;
            margin-bottom: 5px;
            padding-bottom: 160px;

            @media (min-width: 380px) {
                padding-bottom: 120px;
            }

            @include media-breakpoint-up(md) {
                padding-bottom: 100px;
            }

            h1 {
                margin: 0;
                line-height: 30px;
            }

            .logo-title {
                height: 30px;
            }

            .operator-wrapper {
                @media (max-width: 379px) {
                    width: 100%;
                }

                @include media-breakpoint-up(lg) {
                    height: 42px;
                }
            }

            .operatedNote {
                @include media-breakpoint-up(lg) {
                    line-height: 18px;
                    vertical-align: top;
                }
            }

            .operatedLogo {
                max-width: 110px;
            }
        }

        .teaser-item-col-header-image {
            position: relative;
            width: 100%;

            img {
                width: 100%;
            }

            .snowOverlay {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100px;
                @include retina("../img/snow_teaser.png", 2, "100% 100%");
                background-size: 100% 120px;
                background-repeat: no-repeat;
            }
        }

        .teaser-item-col-header-content {
            position: absolute;
            bottom: 10px;
            padding: 0 15px;
            width: 100%;
        }

        .teaser-item-col-body {
            padding: 15px;

            > .row {
                height: 260px;
            }

            .season-dates {
                margin-bottom: 15px;
                font-size: 13px;

                .trip-days {
                    @include media-breakpoint-down(md) {
                        display: inline-block;
                        width: 100%;
                        padding-left: 30px;
                    }
                }
            }

            .datesTitle {
                line-height: 24px;
            }

            .description {
                overflow: hidden;
                padding-left: 0;

            }

            ul.dates {
                .location {
                    @include media-breakpoint-between(sm,md) {
                        display: inline-block;
                        width: 100%;
                    }

                    margin-left: 19px;
                }

                .date {
                    width: auto;
                }

                @include media-breakpoint-down(md) {
                    li {
                        font-size: 14px;
                    }
                }
            }
        }

        &.kitzskixpress {
            .priceWrapper {
                .icon-pricetag {
                    fill: $kitzSkiXpressColor;
                }
            }
        }
    }
}

/******************
 * Image Teaser Area
 ******************/

section.imageTeaserArea {
    position: relative;
    padding-top: 110px;
    width: 100%;
    height: 800px;
    background-size: cover;
    background-position: bottom center;

    &.editmode {
        background: $color-gray;
        height: auto;
        padding-bottom: 50px;
    }

    .well {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05)
    }

    h1 {
        @include font-h2();
        color: $white;
        text-align: center;
    }

    ul {
        position: relative;
        left: 50%;
        @include translate(-50%, 0);
        display: inline-block;
        margin-top: 20px;

        li {
            display: flex;
            @include font-h4();
            font-weight: $font-weight-light;
            color: $white;
            list-style: none;
            margin-bottom: 15px;

            span.icon {
                position: relative;
                width: 25px;
                height: 25px;
                margin-right: 15px;

                svg {
                    width: 25px;
                    height: 25px;
                    fill: $white;
                }
            }
        }
    }

    .btn-more {
        position: relative;
        @include font-h3();
        font-weight: $font-weight-bold;
        color: $white;
        border-color: $white;
        height: 60px;
        line-height: 46px;
        margin-top: 35px;
        transition: border-color .4s ease-in-out;

        &:hover {
            border-color: transparent;
        }

        .priceTagWrapper {
            position: absolute;
            left: -35px;
            top: -42px;
            width: 140px;
            height: 50px;

            span.icon-pricetag {
                position: absolute;
                top: 0;
                left: 0;
                width: 140px;
                height: 50px;
                overflow: hidden;

                svg {
                    position: relative;
                    top: -52px;
                    fill: $color-lightBlue;
                    width: 140px;
                }
            }

            .price {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                @include font-h3();
                font-size: 27px;
                text-transform: none;
                text-align: center;
                color: $white;
                font-weight: $font-weight-bold;
                margin-top: 7px;
            }
        }

        span.icon-arrow-right {
            position: relative;
            top: -3px;

            svg {
                fill: $white;
                width: 20px;
                height: 20px;
            }
        }
    }

}

/******************
 * Ski Locations Teaser Box Area
 ******************/

section.skiLocationTeaserBoxArea {
    position: relative;
    padding-bottom: 80px;
    margin-top: 55px;

    &.editmode {
        margin-top: 0;
    }

    > .container > h1 {
        @include font-h2();
        text-align: center;
        color: $color-blue
    }

    section.teaserBoxWrapper article.teaserItem .itemLink {
        padding: 25px 35px;
    }

    section.teaserBoxWrapper {
        article.teaserItem {
            h1.title {
                text-transform: none;
            }
        }
    }
}

section.teaserBoxWrapper {
    margin-top: 60px;

    article.teaserItem {
        position: relative;
        max-width: 555px;
        margin: 15px auto;

        .itemLink {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 15px 35px;
            text-decoration: none;
        }

        h1.title {
            @include font-h3();
            line-height: 44px;
            color: $white;
            font-weight: $font-weight-bold;
            margin-bottom: 0;
            position: relative;
            z-index: 1;
            margin-top: 0;
        }

        a.skiBusTooltip {
            position: absolute;
            width: 15px;
            height: 15px;
            top: 10px;
            left: 10px;
            z-index: 2;

            svg.icon-copyright {
                position: relative;
                width: 15px;
                height: 15px;
                fill: $color-lightBlue;
            }
        }

        .overlayContent {
            position: relative;
            z-index: 1;
            opacity: 0;
            @include transition(all .4s ease-in-out);

            h2 {
                @include font-copy-s();
                color: $white;
                font-weight: $font-weight-light;
                margin-top: 0;
                text-align: left;
            }

            .ctaButton {
                border: 1px solid $white;
                color: $white;
                padding: 5px 15px;
                display: inline-block;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: -moz-linear-gradient(top, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.2) 40%, rgba(19, 38, 45, 0) 50%);
            background: -webkit-linear-gradient(top, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.2) 40%, rgba(19, 38, 45, 0) 50%);
            background: linear-gradient(to bottom, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.2) 40%, rgba(19, 38, 45, 0) 50%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#13262d', endColorstr='#0013262d', GradientType=0);
            padding: 15px 50px;
            opacity: 0.7;
            @include transition(all .4s ease-in-out);
        }

        .operatedLogo {
            position: absolute;
            right: 15px;
            bottom: 15px;
            opacity: 0;
            @include transition(opacity .4s ease-in-out);
        }

        .skiAreaImage {
            width: 100%;
        }

        &:hover {
            .overlayContent {
                opacity: 1;
            }

            .overlay {
                opacity: 1;
                background: -moz-linear-gradient(top, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.17) 83%, rgba(19, 38, 45, 0) 100%);
                background: -webkit-linear-gradient(top, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.17) 83%, rgba(19, 38, 45, 0) 100%);
                background: linear-gradient(to bottom, rgba(19, 38, 45, 1) 0%, rgba(19, 38, 45, 0.17) 83%, rgba(19, 38, 45, 0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#13262d', endColorstr='#0013262d', GradientType=0);
            }

            .operatedLogo {
                opacity: 1;
            }
        }
    }
}

section.mapArea {
    position: relative;

    #skiLocationsGoogleMap {
        position: relative;
        width: 100%;
        height: 750px;
    }
}

section.newsletterArea {
    position: relative;
    padding-top: 0;
    padding-bottom: 210px;
    background: $color-lightBlue;
    overflow: hidden;

    h1 {
        @include font-h4();
        text-align: center;
        color: $white;
    }

    .newsletterFormWrapper {
        form.newsletter {
            margin-top: 25px;

            .emailWrapper {
                display: inline-block;
                float: left;
                width: 80%;
                height: 45px;
                padding-right: 4px;

                input[name="email"] {
                    display: inline-block;
                    width: 100%;
                    height: 45px;
                    background: none;
                    border: none;
                    border-bottom: 1px solid $white;
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    border-radius: 0;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    color: $white;
                    @include placeholder($white);
                }
            }

            button {
                width: 20%;
                height: 45px;
                display: inline-block;
                float: left;
                background: none;
                color: $white;
                border-color: $white;
            }
        }
    }
}

section.stage-area {
    position: relative;
    padding-top: 60px;
    padding-bottom: $brick-padding;
    z-index: 5;
    background: $white;

    .alert.alert-danger {
        margin-top: 100px;
    }

    &.editmode {
        min-height: 300px;
        padding-top: 50px;
        padding-bottom: 50px;
        background: $color-gray-light;
        display: flex;
        align-items: center;
    }

    &.blue {
        background: $color-lightBlue;
    }

    &.red {
        background: $kitzSkiXpressColor;

        .btn-more {
            &:hover {
                background-color: white;
                color: $kitzSkiXpressColor;

                svg {
                    fill: $kitzSkiXpressColor;
                }
            }
        }
    }

    h1, h2, h3, h4, h5 {
        margin-top: 0;
    }

    .b-wysiwyg {
        ul {
            padding: 0;
            margin-bottom: 0;
            font-size: 18px;
            line-height: 25px;
            color: $color-blue;
            font-family: $font-family-base;
            font-weight: $font-weight-light;

            strong {
                font-weight: $font-weight-bold;
            }

            li {
                position: relative;
                list-style: none;
                margin-bottom: 6px;
                padding-left: 25px;

                &:before {
                    position: absolute;
                    top: 7px;
                    left: 0;
                    content: '';
                    background: url('../../../../../src/brands/sbm/icomoon/SVG/bullet-dark-blue.svg');
                    background-size: 11px 11px;
                    width: 11px;
                    height: 11px;
                }

                a {
                    text-decoration: none;
                }
            }
        }
    }
}

section.extra-content-snippet {
    padding-bottom: 100px;

    .disturber-img {
        margin-bottom: 30px;
        width: 250px;

        @include media-breakpoint-down(sm) {
            margin-top: 30px;
        }
    }

    .contact-info-text, .contact-info-box {
        font-size: 15px;
    }

    .contact-info-box {
        display: inline-block;
        margin-bottom: 15px;
        padding: 10px 18px;
        color: white;
        background-color: $kitzSkiXpressColor;

        &:not(:last-of-type) {
            margin-right: 10px;
        }

        @include media-breakpoint-between(sm,md) {
            display: block;

            &:not(:last-of-type) {
                margin-right: 0;
            }
        }

        @media (max-width: 379px) {
            display: block;

            &:not(:last-of-type) {
                margin-right: 0;
            }
        }
    }
}

.tooltipster-sidetip {
    .tooltipster-box {
        border: none;
        background: $white;
        padding: 10px;
        @include box-shadow(2px 2px 5px 1px rgba(115, 115, 115, 0.5));
        max-width: 400px;

        p, li {
            font-size: 12px;
        }

        img {
            @include img-responsive();
        }
    }

    .tooltipster-content {
        padding: 5px;
        color: $color-blue;
    }
}

.siteOverlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9997;
}


#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    background-color: #cdcdcd!important;
    border-color: #cdcdcd!important;
}

// Swiper Slider Styles

.ski-location-top-slider, .ski-location-night-top-slider {
    .swiper-wrapper {
        height: fit-content;
    }
}

.ski-location-main-slider, .ski-location-night-main-slider {
    .swiper-button-next, .swiper-button-prev {
        top: 28%;
    }
}


.btn-booking {
    @include font-h4();
    color: $white;
    background: $color-green;
    text-align: center;
    text-transform: uppercase;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 6px 15px;
    border: 2px solid $white;
    margin: 11px auto 0;
    @include transition(all .4s ease-in-out);
    max-width: 100%;
    text-overflow: ellipsis;
    @media (max-width: $screen-md-max) {
        font-size: 20px !important;
    }

    &:hover {
        color: $white;
        background: darken($color-green, 10%);
    }
}

.ski-detail-page section.contentArea.location-facts {
    padding: 70px 0 140px 0;
}

section.skiLocationDetailContent {
    strong {
        font-weight: $font-weight-bold;
    }
}

.priceTagWrapper {
    .price {
        margin-top: 2px;
    }
}